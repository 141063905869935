// Default variable overrides
$body-bg: rgb(240, 240, 240);
$body-bg2: rgb(230, 230, 230);
$body-color: black;
$primary: rgb(0, 31, 90);
$secondary: rgb(192, 0, 0);
$third: rgb(0, 57, 172);

$font-family-sans-serif: 'Lato', sans-serif;

@import "../../node_modules/bootstrap/scss/bootstrap";

html {
    scroll-padding-top: 4rem; /* height of sticky header */
}

@media (min-width: 768px) {
    html {
        scroll-padding-top: 6rem; /* height of sticky header */
    }   
}

.navbar,h2,h3,h4,h5,h6 {
    font-family: 'Roboto Slab', serif;
    }

h1 {
    font-family: 'Roboto Slab', serif;
    text-transform: uppercase;
    }

.navbar-collapse {
    justify-content: flex-end;
}

@media (min-width: 992px) {
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
}

section {
    min-height: 50vh;
}

.hero-container {
    background: black url('../../assets/images/Operetta Land 2 - Bart Grietens.jpg');
    background-size: cover;
    background-position: center center;
    padding-top: 6rem;
    padding-bottom: 3rem;
    height: calc(100vh - 6rem);
    background-attachment: fixed;
    position: relative;
    z-index: 2;
}

.hero-container::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0rem;
    left: 0rem;
    background-color: rgba(0, 57, 172, 0.4);
    z-index: -1;
}

.hero-section-heading {
    background: $secondary;
    background-size: cover;
    background-position: center center;
    padding-top: 6rem;
    padding-bottom: 3rem;
}

.hero-section {
    background: $body-bg url('../../assets/images/Applaus La Boheme Carré - Veerle Bastiaanssen.jpg');
    background-size: cover;
    background-position: center center;
    padding-top: 6rem;
    padding-bottom: 3rem;
    min-height: 500px;
    background-attachment: fixed;
    position: relative;
    z-index: 2;
}

.hero-section::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0rem;
    left: 0rem;
    background-color: rgba(192, 0, 0, 0.05);
    z-index: -1;
}

.standard-section {
    padding-top: 6rem;
    padding-bottom: 3rem;
    min-height: 400px;
}

.topic {
    padding-top: 5rem;
    padding-bottom: 5rem;
    min-height: 50vh;
}

.extended-topic {
    padding-top: 6rem;
    padding-bottom: 3rem;
    min-height: 100vh;
}

.bg2 {
    background: $body-bg2;
}

.text-outline-dark {
    text-shadow: -1px -1px 0 rgba(0, 0, 0, 0.4), 1px -1px 0 rgba(0, 0, 0, 0.4), -1px 1px 0 rgba(0, 0, 0, 0.4), 1px 1px 0 rgba(0, 0, 0, 0.4);
}

.text-outline-light {
    text-shadow: -1px -1px 0 rgba(255, 255, 255, 0.3), 1px -1px 0 rgba(255, 255, 255, 0.3), -1px 1px 0 rgba(255, 255, 255, 0.3), 1px 1px 0 rgba(255, 255, 255, 0.3);
}

.card {
    margin-bottom: 2rem;
}

.card-title {
    font-weight: bold;
}

.card-img, .card-img-top {
    object-fit: cover;
}

.home
    .card-img, .card-img-top {
    aspect-ratio: 8/5;
}

.primary {
    color: $primary
}

.secondary {
    color: $secondary
}

.third {
    color: $third
}

.bg-third {
    background: $third;
    background-color: $third;
}

.text-bg-third {
    color: white;
    background: $third;
    background-color: $third !important;
    opacity: 1;
}

.white {
    color: rgb(255,255,255);
}

.carousel {
    width: auto;
    max-height: 90vh;
}

.carousel-item {
    height: auto;
    max-height: 90vh;
}

.carousel-caption {
    width:100%;
    min-height: 20%;
    left:0px;
    right:0px;
    bottom:0px;
    background-color:rgba(0,0,0,0.8);
    padding-left: 5%;
    padding-right: 5%;
}

.carousel-overlay {
    z-index: 500;
    width: 90%;
}

.ol-background {
    background: black url('../../assets/images/Operetta Land 2 - Bart Grietens.jpg');
    background-size: cover;
    background-position: center center;
    z-index: 2;
}

.bb-background {
    background: black url('../../assets/images/Blauwbaard 1 - Jessie Kamp.webp');
    background-size: cover;
    background-position: center center;
    z-index: 2;
}

.lb-background {
    background: black url('../../assets/images/La Boheme - Veerle Bastiaanssen - Cropped.jpg');
    background-size: cover;
    background-position: center center;
    z-index: 2;
}

.production-smaller-display {
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 5;
}

.card-img-overlay {
    color: white;
    background: rgba(0, 0, 0, 0.2);
    transition: all ease-in-out 0.3s;
    opacity: 1;
}

.card:hover .card-img-overlay {
    content: "";
    opacity: 0.5;
}

.card-hover {
    opacity: 0;
    transition: all ease-in-out 0.3s;
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: center;
    z-index: 200;
}

.card:hover .card-hover {
    background: rgba(255, 255, 255, 0.75);
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    opacity: 1;
}

.side-logo {
    max-width: 10%;
    max-height: 5rem;
}